.bokeh {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.bokeh-light {
    border-radius: 50%;
    position: absolute;
}

@keyframes focus {
    0% {
        filter: blur(2px);
    }
    100% {
        filter: blur(20px);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-size {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
